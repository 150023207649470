@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Charter";
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  src: url(./assets/fonts/Charter/Charter-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Charter";
  font-style: italic;
  font-weight: normal;
  font-stretch: normal;
  src: url(./assets/fonts/Charter/Charter-Italic.ttf) format("truetype");
}

@font-face {
  font-family: "Charter";
  font-style: normal;
  font-weight: bold;
  font-stretch: normal;
  src: url(./assets/fonts/Charter/Charter-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Charter";
  font-style: italic;
  font-weight: bold;
  font-stretch: normal;
  src: url(./assets/fonts/Charter/Charter-Bold-Italic.ttf) format("truetype");
}

.no-mouse-focus-ring:focus:not(:focus-visible) {
  @apply focus:ring-0 focus:ring-offset-0;
}

.no-mouse-focus-ring:focus-visible {
  @apply ring-2 ring-offset-2;
}
